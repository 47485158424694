import Player from './components/vimeo-player.js'

const videoElements = document.querySelectorAll('.vimeo-vid');
    const videoElementsArray = [...videoElements]; // converts NodeList to Array



    function initVimeoVid(videoElement, index) {

        var options = {
			url: "https://player.vimeo.com/" + videoElement.dataset.vimeoId,
            title: false,
            portrait: false,
            byline: false,
            autopause: true,
            responsive: true,
            controls: false
			//width: 800,
			//background: true,
			//quality: '1080p'
		};

        videoContainer = videoElement.querySelector('.vimeo-vid-embed');
        videoPlayer = new Vimeo.Player(videoContainer, options);

        play = videoElement.querySelector('.vid__control--play');
        pause = videoElement.querySelector('.vid__control--pause');

        setPlayingState = function(){
            videoElement.classList.add('vid--playing');
        }

        setPausedState = function(){
            videoElement.classList.remove('vid--playing');
        }

        resetVideo = function(){
            videoPlayer.setCurrentTime(0);
            setPausedState();
        }

        play.addEventListener('click', function(evt) {
            setPlayingState();
            videoPlayer.play();
        })

        pause.addEventListener('click', function(evt) {
            setPausedState();
            videoPlayer.pause();
        })

        

    }



    videoElementsArray.forEach((videoElement, index) => {

        // initVimeoVid(videoElement);

        const vimeoPlayer = new Player(videoElement, videoElement.dataset.vimeoId)


        // var playing = false;

        // var videoContainer = videoElement.querySelector('.vimeo-vid-embed');

        // // console.log(videoContainer);



        // var play = videoElement.querySelector('.vid__control--play');
        // var pause = videoElement.querySelector('.vid__control--pause');

        // setPlayingState = function(){
        //     videoElement.classList.add('vid--playing');
        // }

        // setPausedState = function(){
        //     videoElement.classList.remove('vid--playing');
        // }

        // resetVideo = function(){
        //     videoPlayer.setCurrentTime(0);
        //     setPausedState();
        // }

        // play.addEventListener('click', function(evt) {
        //     setPlayingState();
        //     videoPlayer.play();
        // })

        // pause.addEventListener('click', function(evt) {
        //     setPausedState();
        //     videoPlayer.pause();
        // })

        // videoPlayer.on('ended', resetVideo);

    })


		// videoPlayer.on('playing', function() {
		// 	document.body.classList.add('playing');
		// });