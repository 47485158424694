export default class Player {
    constructor(playerEl, vimeoId) {

        var options = {
            url: "https://player.vimeo.com/" + vimeoId,
            title: false,
            portrait: false,
            byline: false,
            autopause: true,
            responsive: true,
            controls: false
        };

        var _this = this;
        this.playerEl = playerEl;
        this.videoContainer = this.playerEl.querySelector('.vimeo-vid-embed');
        this.playButton = this.playerEl.querySelector('.vimeo-vid__control--play');
        this.pauseButton = this.playerEl.querySelector('.vimeo-vid__control--pause');
        this.fullScreenButton = this.playerEl.querySelector('.vimeo-vid__control--full-screen');


        // init player
        this.videoPlayer = new Vimeo.Player(this.videoContainer, options);

        // listeners
        this.playButton.addEventListener('click', function(evt) {
            _this.playVideo();
        })
        this.pauseButton.addEventListener('click', function(evt) {
            _this.pauseVideo();
        })
        this.fullScreenButton.addEventListener('click', function(evt) {
            _this.goFullScreen();
        })
        this.videoPlayer.on('ended', function(data) {
            _this.resetVideo();
        });
        this.videoPlayer.on('pause', function(data) {
            _this.setPausedState();
        });
        this.videoPlayer.on('play', function(data) {
            _this.setPlayingState();
        });

    }
    setPlayingState() {
        this.playerEl.classList.add('vimeo-vid--playing');
    }
    setPausedState() {
        this.playerEl.classList.remove('vimeo-vid--playing');
    }
    playVideo() {
        this.videoPlayer.play();
        this.playerEl.classList.add('vimeo-vid--playing');
    }
    pauseVideo() {
        this.videoPlayer.pause();
    }
    resetVideo() {
        document.exitFullscreen();
        this.videoPlayer.setCurrentTime(1);
        this.pauseVideo();
    }
    goFullScreen() {
        this.playerEl.requestFullscreen()
    }
}